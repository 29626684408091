import React, { createContext, useContext } from 'react'
import { Button, LoadingOverlay, Text } from '@mantine/core'

import { Wall } from '@/components/Wall'
import { useAuth } from '@/hooks/useAuth'
import { useReporterQuery } from '@/queries/reporters'
import { Reporter } from '@/types'

const ReporterContext = createContext<Reporter | null>(null)

export const UserProvider: React.FC = ({ children }) => {
  const { user } = useAuth()

  if (user?.role === 'reporter') {
    return <ReporterProvider>{children}</ReporterProvider>
  }

  if (user?.role === 'admin') {
    return <>{children}</>
  }

  return <LoadingOverlay visible />
}

export const ReporterProvider: React.FC = ({ children }) => {
  const { user, logout } = useAuth()
  const { data: reporter, isLoading, error } = useReporterQuery(user!.id)

  if (error) {
    return (
      <Wall
        heading={
          <Text>
            Sorry, something went wrong, your account couldn't be loaded.
          </Text>
        }
      >
        <Button onClick={() => logout({ returnTo: window.location.origin })}>
          Try again
        </Button>
      </Wall>
    )
  }

  if (isLoading || reporter === undefined) {
    return <LoadingOverlay visible />
  }

  return (
    <ReporterContext.Provider value={reporter!}>
      {children}
    </ReporterContext.Provider>
  )
}

export const useReporter = (): Reporter | null => {
  return useContext(ReporterContext)
}
