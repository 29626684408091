import React, { useEffect, useMemo } from 'react'
import {
  FieldPath,
  Form,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Group, Stack, Text } from '@mantine/core'
import * as yup from 'yup'

import { GuardianInput } from './GuardianInput'
import { OpeningVerbatim } from './OpeningVerbatim'
import { SwearIn } from './SwearIn'
import { WitnessInput } from './WitnessInput'

import { AttendeeCard } from '@/components/Event/NewScript/components/common/AttendeeCard/AttendeeCard'
import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'
import { ActionButton } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionButton'
import { ActionContainer } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionContainer'
import { ActionText } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionText'
import { ActionTitle } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionTitle'
import { ContinueButton } from '@/components/Event/NewScript/components/common/ScriptTimeline/ContinueButton'
import { ScriptTimeline } from '@/components/Event/NewScript/components/common/ScriptTimeline/ScriptTimeline'
import { TimelineAction } from '@/components/Event/NewScript/components/common/ScriptTimeline/TimelineAction'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useStepActions } from '@/components/Event/NewScript/hooks/state/useStepActions'
import { useStatePolicies } from '@/components/Event/NewScript/hooks/useStatePolicy'
import { openingStepSchema } from '@/components/Event/NewScript/schemas/openingStepSchema'
import {
  getEventTypeLongLabel,
  getEventTypeShortLabel,
  isExaminationUnderOath,
} from '@/components/Event/NewScript/utils/event'
import { AttendeeRole, ScriptStep, TimestampType } from '@/constants'
import { useReporter } from '@/providers/UserProvider'
import { ScriptAttendee, ScriptStepProps } from '@/types'

export type OpeningStepType = yup.InferType<typeof openingStepSchema>

export const OpeningStep: React.FC<ScriptStepProps> = ({ event }) => {
  const {
    canContinue,
    completeStepAndContinue,
    completeAction,
    isActionCompleted,
  } = useStepActions(ScriptStep.OPENING, event)
  const reporter = useReporter()
  const { state, actions } = useScriptState(event)
  const { isNY, isPA } = useStatePolicies(event)

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(openingStepSchema),
    shouldFocusError: false,
    defaultValues: {
      attendees: state.attendees,
      isRecordingStarted: state.is_on_record,
    },
  })

  const { update } = useFieldArray({
    control: methods.control,
    name: 'attendees',
  })

  const isEUO = isExaminationUnderOath(event)

  const {
    guardian,
    guardianIndex,
    witnessIndex,
    interpreterIndex,
    witness,
    counsels,
    interpreter,
    witnessFormatted,
  } = useMemo(() => {
    const witnessIndex = state.attendees.findIndex(
      attendee => attendee.role === AttendeeRole.WITNESS,
    )
    const guardianIndex = state.attendees.findIndex(
      attendee => attendee.role === AttendeeRole.GUARDIAN,
    )
    const interpreterIndex = state.attendees.findIndex(
      attendee => attendee.role === AttendeeRole.INTERPRETER,
    )

    const witnessValue =
      witnessIndex !== -1 ? state.attendees[witnessIndex] : undefined
    const guardianValue =
      guardianIndex !== -1 ? state.attendees[guardianIndex] : undefined

    const counsels = state.attendees
      .map<[ScriptAttendee, number]>((attendee, index) => [attendee, index])
      .filter(
        ([attendee, _]) =>
          [
            AttendeeRole.INVESTIGATOR,
            AttendeeRole.OPPOSING_COUNSEL,
            AttendeeRole.STAFF_COUNSEL,
            AttendeeRole.DEFENDANT_ATTORNEY,
            AttendeeRole.PLAINTIFF_ATTORNEY,
          ].includes(attendee.role) && attendee.present,
      )

    const interpreterValue = state.attendees.find(
      attendee => attendee.role === AttendeeRole.INTERPRETER,
    )

    const witnessFormattedValue = witnessValue
      ? witnessValue.name
      : '{witness name}'

    return {
      witnessIndex,
      witness: witnessValue,
      counsels,
      interpreter: interpreterValue,
      interpreterIndex,
      guardian: guardianValue,
      guardianIndex,
      witnessFormatted: witnessFormattedValue,
    }
  }, [state.attendees])

  useEffect(() => {
    // in case a new participant mapped to an existing attendee we need to update the form
    methods.setValue('attendees', state.attendees)
  }, [methods, state.attendees])

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name === 'attendees' || name?.includes('identity_document')) {
        actions.updateAttendees(value.attendees)
      }
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch, state, actions])

  const validateAndCompleteAction = async (
    validationKeys: FieldPath<OpeningStepType>[],
    actionKey: string,
  ) => {
    const results = await Promise.all(
      validationKeys.map(key => methods.trigger(key)),
    )

    if (results.every(isValid => isValid)) {
      completeAction(actionKey)
    }
  }

  return (
    <FormProvider {...methods}>
      <Form>
        <Stack align="flex-start" spacing="xl">
          <ScriptTimeline title="3. Opening">
            {/* Spotlight the witness in the Zoom app */}
            <TimelineAction
              actionKey="spotlight_the_wittness"
              title="Spotlight the witness in the Zoom app"
              onComplete={completeAction}
              completed={isActionCompleted('spotlight_the_wittness')}
            >
              <ActionContainer outer>
                <Stack>
                  <ActionContainer>
                    <ActionTitle>Reminder</ActionTitle>
                    <ActionText>
                      It's critical to spotlight the witness in the Zoom app
                      before going on the record.
                    </ActionText>
                  </ActionContainer>
                </Stack>
              </ActionContainer>
            </TimelineAction>

            {/* Go on the record and open deposition */}
            <TimelineAction
              actionKey="go_on_the_record"
              title={`Go on the record and open ${getEventTypeLongLabel(event)}`}
              onComplete={() =>
                validateAndCompleteAction(
                  ['isRecordingStarted'],
                  'go_on_the_record',
                )
              }
              completed={isActionCompleted('go_on_the_record')}
            >
              <ActionContainer outer>
                <Stack>
                  <ActionText mono>
                    Is everyone ready to go on the record?
                  </ActionText>
                  <ActionContainer>
                    <Group>
                      <ActionButton
                        onClick={() => {
                          methods.setValue('isRecordingStarted', true)
                          methods.trigger('isRecordingStarted')
                          actions.addTimestamp({
                            isOnRecord: true,
                            timestampType: TimestampType.RECORDING_ON,
                            timezone: event.team.timezone as string,
                          })
                        }}
                        disabled={Boolean(state.timestamps)}
                      >
                        Go on record
                      </ActionButton>
                      {state.timestamps && (
                        <Text weight={700} size="xs">
                          You are now on the record.
                        </Text>
                      )}
                    </Group>
                  </ActionContainer>
                  {/* Form error */}
                  {!!methods.formState.errors.isRecordingStarted?.message && (
                    <FormErrors
                      errors={
                        methods.formState.errors.isRecordingStarted.message
                      }
                    />
                  )}
                </Stack>
              </ActionContainer>
              {!event.case.number && (
                <ActionContainer outer>
                  <ActionContainer>
                    <ActionTitle>Reminder</ActionTitle>
                    <ActionText>
                      Before going on record, please ask the counsel to provide
                      case number.
                    </ActionText>
                  </ActionContainer>
                </ActionContainer>
              )}
              <ActionContainer outer>
                <ActionText mono>
                  <OpeningVerbatim
                    reporter={reporter}
                    event={event}
                    isNY={isNY}
                    isPA={isPA}
                  />
                </ActionText>
              </ActionContainer>
            </TimelineAction>

            {/* Identify the appearence of all present counsels for both parties */}
            <TimelineAction
              actionKey="identify_counsels"
              title="Identify the appearance of all present counsels for both parties"
              onComplete={key =>
                validateAndCompleteAction(
                  counsels.map<FieldPath<OpeningStepType>>(
                    ([_, index]) => `attendees.${index}`,
                  ),
                  key,
                )
              }
              completed={isActionCompleted('identify_counsels')}
            >
              {counsels.map(([attendee, index]) => (
                <ActionContainer outer key={attendee.attendee_id}>
                  <Stack>
                    <ActionText mono>
                      {isEUO
                        ? `Could the ${
                            attendee.role === AttendeeRole.INVESTIGATOR
                              ? 'investigator'
                              : attendee.role === AttendeeRole.OPPOSING_COUNSEL
                                ? 'opposing counsel'
                                : 'staff counsel'
                          } identify themself and spell their last name for the record?`
                        : `Could the counsel for the ${
                            attendee.role === AttendeeRole.DEFENDANT_ATTORNEY
                              ? 'defense'
                              : 'plaintiff'
                          }  identify themself and spell their last name for the record?`}
                    </ActionText>
                    <AttendeeCard
                      attendee={attendee}
                      event={event}
                      isPresenceDisabled={true}
                      namePrefix={`attendees.${index}`}
                      onSubmitClick={value => update(index, value)}
                    />
                  </Stack>
                </ActionContainer>
              ))}
            </TimelineAction>

            {/* Let counsels for both parties stipulate */}
            <TimelineAction
              actionKey="let_counsels_stipulate"
              title="Let counsels for both parties stipulate"
              onComplete={completeAction}
              completed={isActionCompleted('let_counsels_stipulate')}
            >
              <ActionContainer outer>
                <Stack>
                  <ActionText mono>
                    For the record, do all parties stipulate to this{' '}
                    {getEventTypeShortLabel(event)} being conducted remotely?
                  </ActionText>
                  <ActionContainer>
                    <ActionTitle>Reminder</ActionTitle>
                    <ActionText>
                      Attorneys for both parties needs to stipulate to say
                      “yes”, or “I stipulate”.
                    </ActionText>
                  </ActionContainer>
                </Stack>
              </ActionContainer>
            </TimelineAction>

            {interpreter && (
              <TimelineAction
                actionKey="identify_interpreter"
                title="Identify the appearance of the interpreter and do swear-in"
                onComplete={key =>
                  validateAndCompleteAction(
                    [`attendees.${interpreterIndex}`],
                    key,
                  )
                }
                completed={isActionCompleted('identify_interpreter')}
              >
                <ActionContainer outer>
                  <Stack>
                    <ActionText mono>
                      Could the interpreter identify themself and spell their
                      last name for the record?
                    </ActionText>
                    <AttendeeCard
                      attendee={interpreter}
                      event={event}
                      isPresenceDisabled={true}
                      namePrefix={`attendees.${interpreterIndex}`}
                      onSubmitClick={value => update(interpreterIndex, value)}
                    />
                  </Stack>
                </ActionContainer>
                <ActionContainer outer>
                  <ActionText mono>
                    Please raise your right hand. Do you solemnly swear that you
                    will interpret the following questions from English into{' '}
                    {`{language}`}, and the answers from {`{language}`} into
                    English to the best of your ability?
                  </ActionText>
                </ActionContainer>
              </TimelineAction>
            )}

            {guardian && witness && (
              <TimelineAction
                actionKey="identify_guardian"
                title="Identify the appearance of guardian for the witness"
                onComplete={key =>
                  validateAndCompleteAction(
                    [
                      `attendees.${guardianIndex}`,
                      `attendees.${guardianIndex}.identity_document.status`,
                      `attendees.${guardianIndex}.identity_document.document_type`,
                    ],
                    key,
                  )
                }
                completed={isActionCompleted('identify_guardian')}
              >
                <GuardianInput
                  event={event}
                  isPresenceDisabled={true}
                  namePrefix={`attendees.${guardianIndex}`}
                  guardian={guardian}
                  witnessName={witnessFormatted}
                  attendeeChanged={value => update(guardianIndex, value)}
                />
              </TimelineAction>
            )}

            {witness && (
              <TimelineAction
                actionKey="identify_wittness"
                title="Identify the witness"
                onComplete={key =>
                  validateAndCompleteAction(
                    [
                      `attendees.${witnessIndex}`,
                      `attendees.${witnessIndex}.identity_document.status`,
                      `attendees.${witnessIndex}.identity_document.document_type`,
                    ],
                    key,
                  )
                }
                completed={isActionCompleted('identify_wittness')}
              >
                <WitnessInput
                  event={event}
                  isPresenceDisabled={true}
                  namePrefix={`attendees.${witnessIndex}`}
                  witness={witness}
                  attendeeChanged={value => update(witnessIndex, value)}
                />
              </TimelineAction>
            )}
            {witness && (
              <TimelineAction
                actionKey="swear_in_witness"
                title="Swear in the witness"
                onComplete={completeAction}
                completed={isActionCompleted('swear_in_witness')}
              >
                <SwearIn witness={witness} event={event} />
              </TimelineAction>
            )}

            <ContinueButton
              event={event}
              label="Continue to Questioning"
              canContinue={canContinue()}
              step={ScriptStep.OPENING}
              handleClick={() =>
                completeStepAndContinue(ScriptStep.QUESTIONING)
              }
            />
          </ScriptTimeline>
        </Stack>
      </Form>
    </FormProvider>
  )
}
