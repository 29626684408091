import { useMutation, useQuery } from 'react-query'
import axios from 'axios'
import format from 'date-fns/format'

import { useReporter } from '@/providers/UserProvider'

export type Availability = {
  id: string
  starts_at: string
  ends_at: string
  duration: number
  url: string
}

export const useAvailabilityQuery = (interval?: Interval) => {
  const reporter = useReporter()

  return useQuery(
    ['availability', interval],
    () =>
      axios
        .get<Availability[]>(reporter?.availabilities_url!, {
          params: {
            since: format(interval?.start!, 'yyyy-MM-dd'),
            until: format(interval?.end!, 'yyyy-MM-dd'),
          },
        })
        .then(r => r.data),
    { enabled: interval !== undefined && reporter !== null },
  )
}

export const useCreateAvailabilityMutation = () => {
  const reporter = useReporter()

  return useMutation((payload: Pick<Availability, 'starts_at' | 'ends_at'>) =>
    axios
      .post<Availability>(reporter?.availabilities_url!, payload)
      .then(r => r.data),
  )
}

export const useUpdateAvailabilityMutation = () =>
  useMutation((availability: Availability) =>
    axios.patch<Availability>(availability.url, availability).then(r => r.data),
  )

export const useRemoveAvailabilityMutation = () =>
  useMutation((availability: Availability) =>
    axios.delete(availability.url).then(r => r.data),
  )
