import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Stack, Text, useMantineTheme } from '@mantine/core'
import parseISO from 'date-fns/parseISO'

import { BookEventButton } from '@/components/Event/BookEventButton'
import { Card } from '@/components/Event/Detail/components/Card'
import { EventRow } from '@/components/Event/EventRow'
import { useReporter } from '@/providers/UserProvider'
import { useAvailableReporterEventsQuery } from '@/queries/reporterEvents'
import { toCalendarParamsPath } from '@/queries/useCalendarParams'
import { Event } from '@/types'
import { formatDate, formatDuration, formatTimeRange } from '@/utils/time'

const AVAILABLE_EVENTS_COUNT = 3

const getAvailableEventsPath = (datetime: string): string => {
  return `/calendar/${toCalendarParamsPath(
    'week',
    parseISO(datetime),
  )}?${new URLSearchParams({ reporter_status_in: 'available' }).toString()}`
}

export const AvailableEventsTeaser: React.FC = () => {
  const reporter = useReporter()
  const theme = useMantineTheme()
  const { data: events, refetch } = useAvailableReporterEventsQuery(
    reporter?.id,
  )

  // display only for reporter - not for admin
  if (!reporter) {
    return null
  }

  if (!events?.length) {
    return null
  }

  const displayedEvents = events.slice(0, AVAILABLE_EVENTS_COUNT)

  return (
    <Card
      heading="Events available for you"
      highlighted
      highlightColor={theme.colors.green[6]}
    >
      <Stack>
        <Text size="sm">
          There are upcoming events available for you. Go to the calendar to
          book events according to your availability.
        </Text>
        <Stack spacing="xs" className="w-full">
          {displayedEvents.map(event => (
            <EventRow
              key={event.id}
              event={event}
              subtitle={
                <>
                  {formatDate(event.datetime, { long: true, separator: ' ' })}
                  <span> &#183; </span>
                  {formatTimeRange(event.datetime, event.duration)} (
                  {formatDuration(event.duration)})
                </>
              }
              right={
                <BookEventButton
                  event={event as Event}
                  onBook={refetch}
                  buttonProps={{
                    compact: true,
                    variant: 'outline',
                    color: 'gray',
                  }}
                />
              }
            />
          ))}
        </Stack>
        <Button
          color="green"
          component={Link}
          to={getAvailableEventsPath(events[0].datetime)}
        >
          Show all available events in the calendar
        </Button>
      </Stack>
    </Card>
  )
}
