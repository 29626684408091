import { Link, Redirect, Switch, useLocation } from 'react-router-dom'
import { Button, Text } from '@mantine/core'
import * as Sentry from '@sentry/react'

import { useReporter } from './providers/UserProvider'

import { MissingReporterSettingsModal } from '@/components/MissingReporterSettingsModal'
import { ProtectedRoute } from '@/components/ProtectedRoute'
import { Wall } from '@/components/Wall'
import {
  CALENDAR_PARAMS_PATH,
  toCalendarParamsPath,
} from '@/queries/useCalendarParams'
import { Dashboard as AdminDashboard } from '@/routes/Admin/Dashboard/Dashboard'
import { Event as AdminEvent } from '@/routes/Admin/Event/Event'
import { NewScript as AdminNewScript } from '@/routes/Admin/Event/NewScript/NewScript'
import { NewScriptStateEditor } from '@/routes/Admin/Event/NewScript/NewScriptStateEditor'
import { Script as AdminScript } from '@/routes/Admin/Event/Script/Script'
import { Reporters } from '@/routes/Admin/Reporters/Reporters'
import { Availability } from '@/routes/Availability'
import { Dashboard } from '@/routes/Dashboard/Dashboard'
import { Event } from '@/routes/Event/Event'
import { NewScript } from '@/routes/Event/NewScript/NewScript'
import { Script } from '@/routes/Event/Script/Script'
import { Settings } from '@/routes/Settings'
import { Waiting } from '@/routes/Waiting'

export const App = () => {
  const defaultCalendarParamsPath = toCalendarParamsPath('week', new Date())
  const reporter = useReporter()
  const { pathname } = useLocation()

  return (
    <Sentry.ErrorBoundary key={location.pathname} fallback={<Error />}>
      <MissingReporterSettingsModal
        opened={
          !!reporter?.has_missing_settings && !pathname.startsWith('/settings')
        }
      />

      <Switch>
        <ProtectedRoute role="admin" path="/admin/reporters" exact>
          <Reporters />
        </ProtectedRoute>
        <ProtectedRoute
          role="admin"
          path={`/admin/calendar/${CALENDAR_PARAMS_PATH}`}
          exact
        >
          <AdminDashboard />
        </ProtectedRoute>
        <ProtectedRoute role="admin" path="/admin/calendar" exact>
          <Redirect to={`/admin/calendar/${defaultCalendarParamsPath}`} />
        </ProtectedRoute>
        <ProtectedRoute role="admin" path="/admin/event/:id" exact>
          <AdminEvent />
        </ProtectedRoute>
        <ProtectedRoute role="admin" path="/admin/event/:id/script" exact>
          <AdminScript />
        </ProtectedRoute>
        <ProtectedRoute role="admin" path="/admin" exact>
          <Redirect to="/admin/calendar" />
        </ProtectedRoute>
        <ProtectedRoute role="admin" path="/admin/event/:id/new-script" exact>
          <AdminNewScript />
        </ProtectedRoute>
        <ProtectedRoute
          role="admin"
          path="/admin/event/:id/new-script/state-debug-panel"
        >
          <NewScriptStateEditor />
        </ProtectedRoute>
        <ProtectedRoute role="admin" path="/admin/*">
          <NotFound />
        </ProtectedRoute>
        <ProtectedRoute
          role="reporter"
          path={`/calendar/${CALENDAR_PARAMS_PATH}`}
          exact
        >
          <Dashboard />
        </ProtectedRoute>
        <ProtectedRoute role="reporter" path="/calendar" exact>
          <Redirect to={`/calendar/${defaultCalendarParamsPath}`} />
        </ProtectedRoute>
        <ProtectedRoute role="reporter" path="/event/:id" exact>
          <Event />
        </ProtectedRoute>
        <ProtectedRoute role="reporter" path="/event/:id/script" exact>
          <Script />
        </ProtectedRoute>
        <ProtectedRoute role="reporter" path="/event/:id/new-script" exact>
          <NewScript />
        </ProtectedRoute>
        <ProtectedRoute role="reporter" path="/availability" exact>
          <Availability />
        </ProtectedRoute>
        <ProtectedRoute role="reporter" path="/waiting" exact>
          <Waiting />
        </ProtectedRoute>
        <ProtectedRoute role="reporter" path="/settings" exact>
          <Settings />
        </ProtectedRoute>
        <ProtectedRoute role="reporter" path="/" exact>
          <Redirect to="/calendar" />
        </ProtectedRoute>
        <ProtectedRoute role="reporter" path="*">
          <NotFound />
        </ProtectedRoute>
      </Switch>
    </Sentry.ErrorBoundary>
  )
}

const NotFound = () => (
  <Wall heading="Page Not Found">
    <Button component={Link} to="/">
      Go home
    </Button>
  </Wall>
)

const Error = () => (
  <Wall heading="An error has occurred">
    <Text size="sm">Please refresh the page</Text>
  </Wall>
)
