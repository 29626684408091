import { skipStepAction } from './stateActions'

import { SCRIPT_INITIAL_STATE } from '@/components/Event/NewScript/constants'
import { useStatePolicies } from '@/components/Event/NewScript/hooks/useStatePolicy'
import { useSaveScriptDataMutation } from '@/components/Event/NewScript/queries/scriptData'
import { isExaminationUnderOath } from '@/components/Event/NewScript/utils/event'
import { ScriptStep } from '@/constants'
import { DRScriptData, Event } from '@/types'

export const useCreateInitialScriptState = ({ event }: { event: Event }) => {
  const { mutateAsync: saveScriptData, isLoading } =
    useSaveScriptDataMutation(event)
  const { canHaveCna } = useStatePolicies(event)

  const initScriptData = () => {
    // Add attendees to the default script data
    const attendees =
      event.attendees?.map(attendee => ({
        attendee_id: attendee.id,
        present: null,
        name: attendee.name,
        email: attendee.email || undefined,
        role: attendee.role,
        transcript_order: null,
        zoom_participants: [],
        identity_document: null,
      })) || []

    let payload: DRScriptData = {
      ...SCRIPT_INITIAL_STATE,
      attendees,
    }

    // TODO - remove this once enabled for everyone, and flag gets removed
    if (event.is_zoombot_recording_enabled !== true) {
      payload = skipStepAction(payload, {
        stepAndActionKeys: [
          {
            step: ScriptStep.PREPARATION,
            key: 'livestream_zoom_bot',
          },
        ],
      })
    }

    if (!canHaveCna) {
      payload = skipStepAction(payload, {
        stepAndActionKeys: [
          {
            step: ScriptStep.ATTENDANCE,
            key: 'cna',
          },
        ],
      })
    }

    if (isExaminationUnderOath(event)) {
      payload = skipStepAction(payload, {
        stepAndActionKeys: [
          {
            step: ScriptStep.CLOSING,
            key: 'collect_information',
          },
          {
            step: ScriptStep.REVIEW,
            key: 'transcript_information',
          },
        ],
      })
    }

    // save the data to BE
    return saveScriptData(payload)
  }

  return { isLoading, initScriptData }
}
